.msgBox{
  background-color: #E6F6FE;
  border: 15px;
  padding-bottom: 10px;
  min-height: 90vh;
  padding: 5px;
}
.searchContainerMain{
  background-color: #E6F6FE;
  text-align: center;
  padding : 0px 30px 45px 30px;
  border: none;
  width: 100%;
  /* position: fixed; */
/* bottom: 0;
width: 90%; */
display: flex;
justify-content: center;
background: var(--outgoing-chat-bg);
border-top: 1px solid var(--incoming-chat-border); 
}
.searchContainer{
  width :100%;
  padding: 16.5px;
  display: flex;
  border: none;
  background-color:#FFFFFF ;
  
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Adjust the shadow as needed */
  
  
}
.searchContainer>input{
  width: 100%;
  font-size: 18px ;
  border: none;
  text-decoration: none;
  color: grey;



 
  /* resize: none;
height: 55px;
width: 100%;
border: none;
padding: 15px;
color: var(--text-color);
font-size: 1rem;
border-radius: 4px;
max-height: 250px;
overflow-y: auto;
background: var(--incoming-chat-bg); */
}
.searchContainer input:focus{
 
outline: 1px solid var(--incoming-chat-border);
  border: none !important;
  text-decoration: none !important;
}


.chats {
  height:70vh;
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 20px;
  margin-bottom: 20px;
  overflow-y: scroll;
  scrollbar-width: thin; 
  

  scrollbar-color: var(--incoming-chat-border) var(--outgoing-chat-bg);

  /* WebKit (Chrome, Safari) specific styles */
  &::-webkit-scrollbar {
      width: 15px;
      scrollbar-gutter: 80px;
  }

  &::-webkit-scrollbar-thumb {
      background-color: var(--incoming-chat-border);
      border-radius: 4px;
      background: var(--prime-blue-gradiant, linear-gradient(180deg, #3CAFE4 0%, #046593 100%));
  }

  &::-webkit-scrollbar-track {
      background-color: white;

  }
  scroll-margin: 20px;
}


.sender{
  text-align: left;
  padding: 20px;
  width: 100%;
  border-radius: 20px;
  background: #046593;
  color: white;
  margin-bottom: 5px;
  margin-top: 5px;

}
.responseMsg{
  width: 654px;
  display: inline-flex;
padding: 9px 76px 20px 19px;
flex-direction: column;
align-items: flex-start;
gap: 12px;
border-radius: 20px;
background: var(--prime-blue-gradiant, linear-gradient(180deg, #3CAFE4 0%, #046593 100%));


}
.msg{
  display: inline;
}
.send-button{
  background:var(--prime-blue-gradiant, linear-gradient(180deg, #3CAFE4 0%, #046593 100%));
  height: 35px;
  padding: 5px;
  border: none;
  cursor: pointer;
  margin-left: 5px;
}
.send-button:disabled {
  background: #dddddd;
  opacity: 0.7; 
}