 
.book {
    display: flex;
  }
  #cover {
    width: 250px !important;
    font-style: italic;
    color:white;
    padding-top: 30%;
    text-align: center;
    height: 400px;
    background-color: rgb(3, 46, 63);
   }/*
  .flip-book {
    width: 250px;
    height: 400px;
    position: relative;
    perspective: 1500px;
  }
  .flip {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: left;
    transform-style: preserve-3d;
    transform: rotateY(0deg);
    transition: .5s;
    color: #000;
  }
  .flip-page {
    transform: rotateY(180deg);
  }
  .front {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #fff;
    box-sizing: border-box;
    padding: 0 13px;
  }
  .back {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    transform: rotateY(180deg);
    border:2px solid red;
    backface-visibility: hidden;
    background: linear-gradient(180deg, #3CAFE4 0%, #046593 100%);
  }
 
  .p1 {
    z-index: 3;
  }
  #p2 {
    z-index: 2;
  }
  #p3 {
    z-index: 1;
  }
  #c1:checked ~ .flip-book #p1 {
    transform: rotateY(-180deg);
    z-index: 1;
  }
  #c2:checked ~ .flip-book #p2 {
    transform: rotateY(-180deg);
    z-index: 2;
  }
  #c3:checked ~ .flip-book #p3 {
    transform: rotateY(-180deg);
    z-index: 3;
  } */
  .back {
  
    width: 250px;
    height: 400px !important;
     
  border-right:1px solid rgb(0, 0, 0,0.8);
   
  background-color: rgb(3, 46, 63);
  }
 .pages {
  width: 500px;
  height: 400px !important;  
}
.next-btn {
  z-index: 99;
  position: absolute;
  bottom: 13px;
  right: 13px;
  cursor: pointer;
  color: #000;
  
}
.back-btn {
  position: absolute;
  bottom: 13px;
  right: 13px;
  cursor: pointer;
  color: #fff;
}
.page {
 
  height: 100%;
  width: 100%;
  user-select: none;
  touch-action: none;
}

.page1 {
 padding: 10px;
  width: 250px;
  height: 400px !important;
  color: black;
  background-color:#fff;
}

