* {
  padding: 0px;
  margin: 0px
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

}

/* .css-y6k6ts-MuiPaper-root-MuiAppBar-root {
  background-color: white;
  color: black;
  border: 1px solid#187ACE !important;
  box-shadow: 0px 0px 15px #0080f0 inset !important;
  border-radius: 16px;
}

.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  color: #fff !important;
} */

/* .css-5ec8nj-MuiPaper-root-MuiAppBar-root{
  background-color: white; 
  color: black;
  border: 1px solid#187ACE !important;
  box-shadow:0px 0px 15px #0080f0 inset, !important ;
  border-radius: 16px;
} */
.App-link {
  color: #61dafb;
}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-20px);
  }

  60% {
    transform: translateY(-10px);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }

}





/* .css-35gk3z-MuiButtonBase-root-MuiPickersDay-root {
  color: #fff !important;
} */

/* .css-196xwup-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected) {
  border-bottom: 2px solid darkred;
  border-right: 0px;
  border-top: 0px;
  border-left: 0px;
  color: #fff;
}

.css-196xwup-MuiButtonBase-root-MuiPickersDay-root {
  border-radius: 0px;
} */
/* 
.css-1tkx1wf-MuiSvgIcon-root-MuiPickersCalendarHeader-switchViewIcon {
  color: #fff;
}

.css-kg9q0s-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button {
  color: #fff;
}

.css-1nkg345-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button {
  color: #fff;
} */

/* .css-nk89i7-MuiPickersCalendarHeader-root{
  background-color: red;
    padding-left: 95px;
    margin-top: 0px;
} */
/* .css-1qn0lg-MuiTableCell-root {
  border-bottom: 0px;
} */

/* .css-7tsgk1-MuiTableCell-root {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.MuiTableCell-root .MuiTableCell-body .MuiTableCell-sizeMedium .makeStyles-cell-179 .css-lvmlkn-MuiTableCell-root {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
} */
/* .css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root {
  padding-inline: 16px !important;
  padding-block: 8px !important;
} */
/* .makeStyles-pdfViewerContainer-17 {
  width: 100% !important; 
  height: 100% !important;
  overflow: hidden !important;
  position: relative !important;
  border-radius: 16px !important;
} */
/* .css-dh6prw{
  height: 90vh !important;
} */
/* .css-6hp17o-MuiList-root-MuiMenu-list{
  display:flex;
  flex-direction: column;
  
} */